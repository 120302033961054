import router from '@/router'
import store from '@/store/'
import viewMap from './native/viewMap'

/**
 * 客戶端偵測
 * @type {{ua_desc, isWeiXin: ua.isWeiXin, isMobile: ua.isMobile, isAndroid: ua.isAndroid, isiOS: ua.isiOS, isiPhone: ua.isiPhone, isiPad: ua.isiPad, isBrowser: ua.isBrowser, isNative: ua.isNative, getDeviceType: ua.getDeviceType, getOsVerion: ua.getOsVerion}}
 */
export const ua = {
  ua_desc: navigator.userAgent,
  /**
   * 检测是否是微信
   */
  isWeiXin: function () {
    return /MicroMessenger/i.test(this.ua_desc)
  },
  /**
   * 检测是否移动设备
   * @returns {boolean}
   */
  isMobile: function () {
    return /Mobile/i.test(this.ua_desc)
  },
  /**
   * 检测是否Android平台
   * @returns {boolean}
   */
  isAndroid: function () {
    return /Android/i.test(this.ua_desc)
  },
  /**
   * 检测是否iOS平台
   * @returns {boolean}
   */
  isiOS: function () {
    return /iPhone OS/i.test(this.ua_desc)
  },
  /**
   * 检测是否iPhone设备
   * @returns {boolean}
   */
  isiPhone: function () {
    return /iPhone/i.test(this.ua_desc)
  },
  /**
   * 检测是否iPad设备
   * @returns {boolean}
   */
  isiPad: function () {
    return /iPad/i.test(this.ua_desc)
  },
  /**
   * 检测是否通过浏览器访问
   * @returns {boolean}
   */
  isBrowser: function () {
    return !this.isNative()
  },
  /**
   * 检测是否通过WebView访问
   * @returns {boolean}
   */
  isNative: function () {
    return /WebView/i.test(this.ua_desc)
  },
  getDeviceType: function () {
    const clientWidth = document.documentElement.clientWidth
    const clientHeight = document.documentElement.clientHeight
    if (this.isAndroid()) {
      if (/Build/.test(this.ua_desc)) {
        return this.ua_desc.match(/[^;]*Build[^\)]*/)[0]
      } else {
        return this.ua_desc
      }
    } else {
      if (clientWidth === 320) {
        if (clientHeight === 480) {
          return 'iphone 4'
        } else {
          return 'iphone 5'
        }
      } else {
        return 'iphone 6+'
      }
    }
  },
  getOsVersion: function () {
    if (this.isAndroid()) {
      return this.ua_desc.match(/[^;]*Android[^;]*/)[0]
    } else if (this.isiOS()) {
      return this.ua_desc.match(/[^\s]*OS\s[^\s]*/)[0]
    }
  }
}

/**
 * 經緯度轉換
 * @type {{MapabcEncryptToBdmap: coordinateExchange.MapabcEncryptToBdmap, BdmapEncryptToMapabc: coordinateExchange.BdmapEncryptToMapabc}}
 */
export const coordinateExchange = {
  mapabcEncryptToBdmap: function (gg_lat, gg_lon) {
    let point = new Object()
    let x_pi = (3.14159265358979324 * 3000.0) / 180.0
    let x = new Number(gg_lon)
    let y = new Number(gg_lat)
    let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi)
    let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi)
    let bd_lon = z * Math.cos(theta) + 0.0065
    let bd_lat = z * Math.sin(theta) + 0.006
    point.lng = bd_lon
    point.lat = bd_lat
    // alert("-1:"+point.lng+","+point.lat);
    return point
  },
  bdmapEncryptToMapabc: function (bd_lat, bd_lon) {
    let point = new Object()
    let x_pi = (3.14159265358979324 * 3000.0) / 180.0
    let x = new Number(bd_lon - 0.0065)
    let y = new Number(bd_lat - 0.006)
    let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi)
    let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi)
    let Mars_lon = z * Math.cos(theta)
    let Mars_lat = z * Math.sin(theta)
    point.lng = Mars_lon
    point.lat = Mars_lat
    return point
  }
}

export function changeTitle(title) {
  if (title === '') return
  document.title = title
  let iframe = document.createElement('iframe')
  iframe.src = '/static/images/tt.png'
  iframe.style.display = 'none'
  iframe.addEventListener('load', function () {
    setTimeout(function () {
      iframe.removeEventListener('load', function () { })
      document.body.removeChild(iframe)
    }, 0)
  })
  document.body.appendChild(iframe)
}

export function fixedBody() {
  document.body.scrollTop = 0
  var scrollTop = document.body.scrollTop || document.documentElement.scrollTop
  document.body.style.cssText += 'position:fixed;top:-' + scrollTop + 'px;'
}

export function looseBody(scrollTop) {
  var body = document.body
  body.style.position = ''
  var top = body.style.top
  document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top)
  body.style.top = ''
  document.body.scrollTop = scrollTop
}

/**
 * 獲取url中的字段
 * @param name
 * @returns {String}
 */
export function getQueryString(name) {
  const reg = new RegExp(`(^|&|#)${name}=([^&#]*)(&|$|#)`, 'i')
  const matchString = window.location.href.split('?')[1]
  const r = matchString && matchString.match(reg)
  if (r != null) return decodeURIComponent(r[2])
  return null
}


export function getQueryStringV2 (name) {
  const reg = new RegExp(`(^|&|#)${name}=([^&#]*)(&|$|#)`, 'i')
  const arr = window.location.href.split('?')
  // 用这种方式, 来处理页面中, 有多个 ? 符号的形式
  arr.shift()
  const matchString = arr.join('&')

  let r = null
  if (matchString) {
    r = matchString.match(reg)
  }

  if (r != null) return decodeURIComponent(r[2])
  return null
}

/**
 * 獲取url中的字段
 * @param name
 * @returns {String}
 */

export function getQuery(name) {
  let search = decodeURIComponent(window.location.href).split('?')
  let args = search.splice(1, search.length)
  if (args.length > 0) {
    for (let i = 0; i < args.length; i++) {
      let reg = new RegExp('(^|&)' + name + '=([^&#]*)(&|$)', 'i')
      let argsI = args[i].split('#')[0]
      let match = argsI.match(reg)
      if (match) {
        return match[2]
        break
      }
    }
  } else {
    return null
  }
}

/**
 * 取小数点
 * @param num 数字
 * @param point 位数
 * @returns {Number}
 */
export function toFixed(num, point) {
  return num.toFixed(point) === parseInt(num.toFixed(point))
    ? parseFloat(num.toFixed(point))
    : parseFloat(num.toFixed(point))
}

/**
 * 向上取整
 * @param value 数字
 * @returns {Number}
 */
export function ceil(value) {
  return Math.ceil(value.toFixed(3) * 100) / 100
}

/**
 * 去引导下载app
 * @param controller app對應的控制器
 * @param time 自动跳下载页面延时
 * MLWaiMaiListController：外賣列表 備註=》如果目的只是打開app就填這個控制器
 * MLMakeOrderViewController：外賣下訂單
 * MLGroupBuyingDetailVC：自助餐詳情
 * MLGroupDetailInfoVC：團購詳情
 * MLCompleteInfoVC：店鋪詳情
 * MLPreferredDetailInfoVC：优选商品详情
 * home：首页
 */
export function getApp(controller, time) {
  time = time || 3000
  window.location.href = 'macaolife://app/home/index'
  window.setTimeout(function () {
    window.location.href =
      'http://a.app.qq.com/o/simple.jsp?pkgname=com.mem.MacaoLife'
  }, time)
}
/**
 * 放在mounted裏自動調用打開app，傳入參數param，包括控制和參數比如：getAppAuto('MLGroupDetailInfoVC?ID=' + this.$route.query.ID)
 */
export function getAppAuto(url, param, vm) {
  if (ua.isWeiXin() && getQueryString('from') === 'wx') {
    vm.$emit('showGuide')
  }
  if (!ua.isWeiXin() && getQueryString('from') === 'wx') {
    h5ToApp(url, param)
  }
}

/**
 * 改變一個連接的url
 * @param {*string} name
 * @param {*string} value
 * @param {*string} name2
 * @param {*string} value2
 */
export function changeUrlParam(name, value, name2, value2) {
  var url = window.location.href
  if (isFromApp(url) && url.indexOf('?') !== url.lastIndexOf('?')) {
    // 如果從app分享過來的，並且是單頁面要做些處理
    url = `${url.split('?')[0]}#${url.split('?')[1].split('#')[1]}?${
      url.split('?')[2]
      }`
  }
  var newUrl = ''
  var reg = new RegExp('(^|)' + name + '=([^&]*)(|$)')
  var tmp = name + '=' + value
  if (url.match(reg) != null) {
    newUrl = url.replace(eval(reg), tmp)
  } else {
    if (url.match('[?]')) {
      newUrl = url + '&' + tmp
    } else {
      newUrl = url + '?' + tmp
    }
  }
  newUrl = name2 ? newUrl + `&${name2}=${value2}` : newUrl
  location.href = newUrl

  function isFromApp(url) {
    if (
      /singlemessage/.test(url) ||
      /timeline/.test(url) ||
      /groupmessage/.test(url)
    ) {
      return true
    } else {
      return false
    }
  }
}
/**
 * 加載多個js文件
 * @param {array} js鏈接名字數組
 */
export function loadScripts(scripts) {
  let scriptsMap = {
    weixinSdk: 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js',
    baiduMap:
      'https://api.map.baidu.com/api?v=2.0&ak=5E5EE28a7615536d1ffe2ce2a3667859&callback=initMap'
  }
  let promises = scripts.map(item => {
    return appendScript(scriptsMap[item], item)
  })
  return Promise.all(promises)
}

/**
 * 在第一个script标签前加载外链js代码
 * @param {string} src
 * @param {string} id
 */

export function appendScript(src, id) {
  let script, head
  return new Promise(function (resolve, reject) {
    if (document.querySelector('#' + id)) {
      return resolve()
    }
    script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src
    script.id = id
    script.onload = function () {
      if (src.indexOf('initMap') != -1) {
        window.initMap = resolve
      } else {
        resolve()
      }
    }
    head = document.getElementsByTagName('head')[0]
    head.appendChild(script)
  })
}

/**
 * 退出登錄
 * @param {funciton} cb
 * 調用時bind(this)
 */
export function loginOut(cb) {
  store.commit('DEL_USER')
  console.log(store)
  localStorage.removeItem('user')
  localStorage.removeItem('userId')
  cb && cb()
}

/**
 * 登錄
 * @param {object} query
 * @param {function} cb
 */
export function toLogin(query, cb) {
  router.push({ path: '/login', query: query })
  cb && cb()
}

/**
 * 判斷是否登錄
 */
export function isLogined() {
  return !!localStorage.user
}

/**
 * 字符串长度（英文占1个字符，中文汉字占2个字符）
 * @param {*字符串} str
 */
export function strlen(str) {
  var len = 0;
  for (var i = 0; i < str.length; i++) {
    var c = str.charCodeAt(i);
    //单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      len++;
    }
    else {
      len += 2;
    }
  }
  return len;
}

/**
 * 获取活动页面路由
 * @param {*本地开发域名可自定义传入} local
 */
export function multipleHost(local) {
  const href = location.href;
  let multipleHost = ['http://localhost/multiple/', 'https://test.aomi.mo/multiple/', 'https://wap.aomi.mo/gray/multiple/', 'https://wap.aomi.mo/multiple/']
  if (local) multipleHost[0] = local;
  if (href.indexOf('localhost') > -1) {
    return multipleHost[0]
  } else if (href.indexOf('test') > -1) {
    return multipleHost[1]
  } else if (href.indexOf('gray') > -1) {
    return multipleHost[2]
  } else {
    return multipleHost[3]
  }
}

function getUtmString() {
  let utmString = ''

  try {
    let utmSource = getQueryString('utm_source')
    let utmMedium = getQueryString('utm_medium')

    if (utmSource && utmMedium) {
      utmString = `utm_source=${utmSource}&utm_medium=${utmMedium}`
    } else if (utmSource) {
      utmString = `utm_source=${utmSource}`
    } else if (utmMedium) {
      utmString = `utm_medium=${utmMedium}`
    } else {
      utmString = ''
    }

    return utmString
  } catch (e) {
    return ''
  }
}

/**
 * author: 王铭浩
 * 由于外部浏览器h5跳转原生app拦截协议升级为aomiapp://
 * 此方法为了过渡兼容新旧协议跳转
 *
 */

export function h5ToApp(url = '', param = {}, type = 1) {
  let type_ = +type
  const param_ = transformParam(url, param)
  let host = ua.isiOS() ? '' : 'openapp'
  const transformAddress = ua.isiOS() ? viewMap[url].ios : viewMap[url].android

  location.href = `aomiapp://${host}?toType=${type_}&needLogin=1&JSONParam=${JSON.stringify(
    param_
  )}&${getUtmString()}&toAddress=${transformAddress}`
  setTimeout(() => {
    // 舊版app的跳轉邏輯
    // 下面是hack，下版本去掉，因為ios映射的控制器替換了
    if (ua.isiOS() && url === 'MLBuffetDetailVC') {
      location.href = `aomi://MLGroupBuyingListVC?ID=${param.id}&${getUtmString()}`
    } else {
      location.href = `aomi://${url}?ID=${param.id}&${getUtmString()}`
    }
  })
  setTimeout(() => {
    location.href = 'http://a.app.qq.com/o/simple.jsp?pkgname=com.mem.MacaoLife'
  }, 1000)
}

// 處理ios跟安卓傳參不同
function transformParam(toAddress, params = {}) {
  if (!params) { return {} }
  if (ua.isiOS()) {
    switch (toAddress) {
      case "MLPreferredDetailInfoVC":
        return { preferredId: params.id }
      case "MLGroupDetailInfoVC":
        return { ID: params.id }
      case "MLBuffetDetailVC":
        return {
          buffetId: params.id
        }
      case "MLGroupBuyingDetailVC": {
        return {
          buffetId: params.id
        }
      }
      case "MLMakeOrderViewController":
        return {
          storeId: params.id
        }
      case 'MLCompleteInfoVC':
        return {
          storeId: params.id
        }
      default:
        return params
    }
  } else {
    switch (toAddress) {
      case "MLPreferredDetailInfoVC":
        return { EXTRA_PARAMS_PREFERRED_ID: params.id }
      case "MLGroupDetailInfoVC":
        return { GROUP_PURCHASE_INFO_ID: params.id, enumTypeValue: 1 }
      case "MLBuffetDetailVC":
        return {
          GROUP_PURCHASE_INFO_ID: params.id,
          enumTypeValue: 2
        }
      case "MLMakeOrderViewController":
        return {
          storeId: params.id
        }
      case 'MLCompleteInfoVC':
        return {
          STORE_INFO_ID: params.id
        }
      default:
        return params
    }
  }
}

export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}

export function timeYearFilter (value) {
  return parseTime(value, '{y}-{m}-{d}')
}
