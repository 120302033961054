/**
 * Created by wfx on 2017/8/24.
 */
import Vue from 'vue'
import Sign from '@aomi/ali-sign'
import axios from 'axios'
import { ua } from './util'
import { env } from './env'
import md5 from 'js-md5'

/**
 * 线上有图片域名：https://wx.miguatech.com
 * 珠海有外卖测试域名，这个域名搭配test wxsdk才可以用：https://www.miguatech.com
 * 灰度测试，这个域名搭配线上环境wxsdk才可以用：http://test.miguatech.com
 * 支付：http://114.215.132.148:8181/service
 * @type {string}
 */
let host = ''

// 不同環境用不同域名
if (env == 'test') {
  console.log('test')
  host = 'https://test-h5-api.aomiapp.com'
} else if (env == 'uat') {
  console.log('uat')
  host = 'https://uat-h5-api.aomiapp.com'
} else if (env == 'gray') {
  console.log('gray')
  host = 'https://gray-h5-api.aomiapp.com'
} else if (env == 'prod') {
  console.log('prod')
  host = 'https://h5-api.aomiapp.com'
  // host = 'https://wx.miguatech.com'
} else if (env == 'mock') {
  host = 'http://localhost:3000'
}

const onAliSign = (function () {
  let sign;

  if (["test", "development"].includes(env)) {
    sign = new Sign("203864673", "3gWcvAw17D3akb86RycArB7PXS5GVQKx");
  } else if (env === "uat") {
    sign = new Sign("203864673", "3gWcvAw17D3akb86RycArB7PXS5GVQKx");
  } else if (env === "gray") {
    sign = new Sign("203866479", "xUMNeAp5nApXZ4dnBvljNElH8qC64I3a");
  } else if (env === "prod") {
    sign = new Sign("203866479", "xUMNeAp5nApXZ4dnBvljNElH8qC64I3a");
  }

  return function (config) {
    if (sign) {
      const signObj = sign.create(config);

      signObj.setHeaders(config.headers);
    }

    return config;
  };
}())

const axios_instance = axios.create({
  method: 'get',
  baseURL: host,
  transformResponse: [
    function (res) {
      let res_p = JSON.parse(res)
      let newRes = {}
      if (Object.prototype.toString.call(res_p) === '[object Array]') {
        newRes = {
          resultCode: Number(res_p[0].resultCode),
          businessMsg: null,
          detailMsg: res_p[1].detailMsg
        }
      } else {
        newRes = res_p
      }
      return newRes
    }
  ],
  headers: {},
  params: {},
  data: {},
  timeout: 10000,
  withCredentials: false,
  validateStatus: function (status) {
    return status >= 200 && status < 300 // default
  }
})

axios_instance.interceptors.request.use(onAliSign)

export const apiHost = host

export function fetch(url, option) {
  let option_ = option || {}
  let timestamp = new Date().getTime()
  let user = localStorage.user ? JSON.parse(localStorage.user) : {}
  let userId = user.userId ? user.userId : 8530000001
  let headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    time: timestamp,
    lanType: 1, // 简体繁体
    userId: userId,
    sign: md5(userId + '19BF01E953ED9FB9' + timestamp), // 簽名
    channel: 3,
    deviceType: ua.getDeviceType(),
    deviceOSVer: ua.getOsVersion(),
    token: user.token ? user.token : '',
    appVersion: '6.3.0',
    cityCode: 'MO',
    channelname: 'USER_H5', // 由于 spa 的使用场景固定, 所以, 我们直接写死它的值
  }
  option_.headers = headers
  option_.url = url
  return axios_instance
    .request(option_)
    .then(res => {
      return res.data
    })
    .catch(function (e) {
      // 超時：timeout of 200ms exceeded
      // if(/timeout/.test(String(e)))throw 'timeout'
      // Error: Network Error
      let stringError = String(e)
      if (stringError.match('timeout') || stringError.match('ERR_TIMED_OUT')) {
        return {
          netErrorType: 'timeout'
        }
      } else {
        // (stringError.match('Network Error'))
        return {
          netErrorType: 'neterror'
        }
      }
    })
}

export function fetchAll(fetchArr, cb) {
  return new Promise(function (resolve, reject) {
    axios.all(fetchArr).then(resolve)
  })
}
